import React, { useRef } from "react";
import axiosBaseUrl from "./apis/connection";

export default function Activation() {
  const shopTelephoneRef = useRef();
  const shopPostcodeRef = useRef();
  const keyNumRef = useRef();
  const numOfDaysRef = useRef();
  const activationCodeRef = useRef();

  const handleGenKey = async (e) => {
    const shopTelephone = shopTelephoneRef.current.value;
    const shopPostcode = shopPostcodeRef.current.value;
    const keyNum = keyNumRef.current.value;
    const numOfDays = numOfDaysRef.current.value;

    try {
      const response = await axiosBaseUrl.get("/getkey", {
        params: {
          telephone: shopTelephone,
          postcode: shopPostcode,
          keynum: keyNum,
          numberOfDays: numOfDays,
        },
      });

      console.log("The Response", response);
      activationCodeRef.current.value = response.data.KeyNumber;
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div>Novusoft Actiovation App</div>
      <div>
        <label htmlFor="telephoneInput">Shop Telephone</label>
        <input id="telephoneInput" ref={shopTelephoneRef} type="text" />
      </div>
      <div>
        <label htmlFor="postcodeInput">Shop Postcode</label>
        <input id="postcodeInput" ref={shopPostcodeRef} type="text" />
      </div>
      <div>
        <label htmlFor="keyNumInput">Key Number</label>
        <input id="keyNumInput" ref={keyNumRef} type="text" />
      </div>
      <div>
        <label htmlFor="dayNumInput">Number of Days</label>
        <input id="dayNumInput" ref={numOfDaysRef} type="text" />
      </div>
      <div>
        <label htmlFor="generatedKeyInput">Activation Key</label>
        <input id="generatedKeyInput" ref={activationCodeRef} type="text" />
      </div>
      <div>
        <button onClick={handleGenKey}>Generate Key</button>
      </div>
    </>
  );
}
